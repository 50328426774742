const axios = require("axios").default;

import SubmitToVendor from "../components/SubmitToVendor";
import ConfirmToPurchaser from "../components/ConfirmToPurchaser";
import DeleteBid from "../components/DeleteBid";
import UploadToCrunchTime from "../components/UploadToCrunchTime";
import ResendToCrunchTime from "../components/ResendToCrunchTime";
import SaveChanges from "../components/SaveChanges";
import Sorter from "../components/Sorter";
import Pagination from "../components/Pagination";
import MarkAsDeleted from "../components/MarkAsDeleted";
import VueFlashbag from "../components/VueFlashbag";

if (document.querySelector(".vue-bid-details")) {
	Vue.component("confirm-to-purchaser", ConfirmToPurchaser);
	Vue.component("submit-to-vendor", SubmitToVendor);
	Vue.component("delete-bid", DeleteBid);
	Vue.component("upload-to-crunchtime", UploadToCrunchTime);
	Vue.component("resend-to-crunchtime", ResendToCrunchTime);
	Vue.component("save-changes", SaveChanges);
	Vue.component("sorter", Sorter);
	Vue.component("pagination", Pagination);
	Vue.component("mark-as-deleted", MarkAsDeleted);
	Vue.component("vue-flashbag", VueFlashbag);

	const BID_STATUS_NEW = 1;
	const BID_STATUS_CLOSED = 4;

	document.appBidDetails = new Vue({
		el: ".vue-bid-details",
		name: "bid details",
		data: {
			bid: {},
			bidErrors: undefined,
			showAdditionalColumns: false,
			attachments: [],
			generalComment: "",
			isShowOnlyErrors: false,
			isDirty: false,
			isSubmitInProgress: false,
			isConfirmInProgress: false,
			spinner: false,
			selectedPos: [],
			filters: {
				productNumber: "",
				productName: "",
				vendorProductNumber: "",
				vendorProductName: "",
				added: "",
				deleted: "",
			},
			sorters: {
				productNumber: null,
				productName: null,
				vendorProductNumber: null,
				vendorProductName: null,
				manualOverride: null,
			},
			currentSorter: null,
			currentSorterManualOverrideType: null,
			page: 1,
			limit: 50,
			manualOverrideEnum: {},
			newItem: {},
			newItemErrors: {},
			downloadTimeout: undefined,
		},
		mounted: function() {
			this.bid = JSON.parse(
				Buffer.from(this.$refs.bidJson.dataset.bidJson, "base64")
			);
			this.manualOverrideEnum = JSON.parse(
				Buffer.from(
					this.$refs.manualOverrideEnum.dataset.manualOverrideEnum,
					"base64"
				)
			);

			if (this.bid.emailTo === null) {
				this.bid.emailTo = [];
			}
			if (this.bid.emailCc === null) {
				this.bid.emailCc = [];
			}
			if (this.bid.emailReplyTo === null) {
				this.bid.emailReplyTo = [];
			}

			this.generateNewItem();
			this.runValidation();
		},
		methods: {
			addNewSecondaryProduct: function(item, index) {
				// this.sorters[this.currentSorter] = null;
				let indexFiltered = (this.page - 1) * this.limit + index;
				this.newItem.productNumber = item.productNumber;
				this.newItem.productName = item.productName;
				this.newItem.inventoryUnit = item.inventoryUnit;
				this.newItem.isSecondaryProduct = true;
				this.bid.items.splice(parseInt(indexFiltered) + 1, 0, this.newItem);
				this.generateNewItem();
				this.isDirty = true;
			},
			removeNewSecondaryProduct: function(index) {
				let indexFiltered = (this.page - 1) * this.limit + index;
				this.bid.items.splice(indexFiltered, 1);
				this.isDirty = true;
			},
			generateNewItem: function() {
				this.newItem = JSON.parse(JSON.stringify(this.bid.items[0]));

				for (let property in this.newItem) {
					switch (typeof this.newItem[property]) {
						case "number":
							this.newItem[property] = null;
							break;
						case "string":
							this.newItem[property] = "";
							break;
						case "boolean":
							this.newItem[property] = false;
							break;
					}
				}

				this.newItem.manualOverride = this.manualOverrideEnum.added;
			},
			errorsForNewItem: function() {
				const checkFieldIsBlank = (fieldName) => {
					if (!this.newItem[fieldName]) {
						this.newItemErrors[fieldName] = "This field cannot be blank";
					}
				};

				this.newItemErrors = {};
				["productNumber", "productName", "inventoryUnit"].forEach((fieldName) =>
					checkFieldIsBlank(fieldName)
				);

				const productNumber = parseInt(this.newItem.productNumber);
				if (isNaN(productNumber)) {
					this.newItemErrors.productNumber = "Product Number must be a integer";
				} else {
					this.newItem.productNumber = productNumber;
				}

				return Object.keys(this.newItemErrors).length;
			},
			getNewItemFieldError: function(fieldName) {
				return this.newItemErrors[fieldName];
			},
			toggleColumnsVisibility: function() {
				this.showAdditionalColumns = !this.showAdditionalColumns;
			},
			addEmail: function(email) {
				email.push("");
			},
			removeEmail: function(email, index) {
				email.splice(index, 1);
				this.isDirty = true;
			},
			addAttachments: function() {
				let uploadedAttachments = this.$refs.attachment.files;
				let formData = new FormData();
				for (let i = 0; i < uploadedAttachments.length; i++) {
					let uploadedAttachment = uploadedAttachments[i];
					formData.append(`attachments[${i}]`, uploadedAttachment);
				}

				this.spinner = true;

				axios
					.post("/bid/" + this.bid.id + "/attachment/upload", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then(this.attachmentUploadSuccess)
					.catch(this.attachmentError)
					.finally(this.attachmentFinally);
			},
			attachmentUploadSuccess: function(response) {
				//console.debug(response);
				this.bid.attachments.push({
					id: response.data.attachment.id,
					originalName: response.data.attachment.originalName,
				});
			},
			attachmentError: function(error) {
				console.debug(error);
				let message =
					"There has been an error while updating the attachments. Please check the validation errors and try again.";
				// for (let error of error.)
				if (
					error.response &&
					error.response.data &&
					error.response.data.errors
				) {
					message += `\n`;
					for (let key of Object.keys(error.response.data.errors)) {
						let errorMessage = error.response.data.errors[key];

						message += `\n${key}: ${errorMessage}`;
					}
				}
				alert(message);
			},
			attachmentFinally: function() {
				this.spinner = false;
			},
			removeAttachment: function(bidAttachmentId) {
				let bidAttachment = null;
				for (let attachment of this.bid.attachments) {
					if (attachment.id === bidAttachmentId) {
						bidAttachment = attachment;
						break;
					}
				}
				if (!bidAttachment) {
					return Promise.reject(`Invalid bid ID ${bidAttachmentId}`);
				}

				this.spinner = true;

				axios
					.post(`/bid/${this.bid.id}/attachment/${bidAttachmentId}/remove`)
					.then(this.attachmentRemoveSuccess)
					.catch(this.attachmentError)
					.finally(this.attachmentFinally);
			},
			attachmentRemoveSuccess: function(response) {
				//console.debug(response);
				let attachmentId = response.data.attachment.id;
				for (let [i, attachment] of this.bid.attachments.entries()) {
					if (attachment.id === attachmentId) {
						this.bid.attachments.splice(i, 1);
					}
				}
			},
			onChanged: function() {
				this.isDirty = true;
				this.runValidation();
			},
			onChangeIsDiscontinued: function(item) {
				item.manualOverride = item.isDiscontinued
					? this.manualOverrideEnum.deleted
					: null;
				this.onChanged();
			},
			runValidation: function() {
				axios
					.post("/bid/" + this.bid.id + "/validate", this.bid.items)
					.then(this.runValidationSuccess)
					.catch(this.runValidationError)
					.finally(this.runValidationFinally);
			},
			runValidationSuccess: function(response) {
				//console.debug(response);
				this.bidErrors = response.data;
			},
			runValidationError: function(error) {
				console.debug(error);
			},
			runValidationFinally: function() {},
			getItemFieldError(item, fieldName) {
				const key = this.hasItemError(item);
				if (!key) {
					return undefined;
				}

				const error = this.bidErrors[key][fieldName];
				if (!error) {
					return undefined;
				}

				return error;
			},
			hasItemError(item) {
				if (!this.bidErrors) {
					return undefined;
				}

				if (item.id) {
					return this.bidErrors[item.id] ? item.id : undefined;
				}

				const key = `UNPERSISTED-${item.productNumber}-${item.vendorProductNumber}-${item.productName}-${item.inventoryUnit}`;
				return this.bidErrors[key] ? key : undefined;
			},
			save: function() {
				this.spinner = true;
				axios
					.post("/bid/" + this.bid.id + "/save", this.bid)
					.then(this.saveSuccess)
					.catch(this.saveError)
					.finally(this.saveFinally);
			},
			saveSuccess: function(response) {
				//console.debug(response);
				window.location.reload(true);
			},
			saveError: function(error) {
				console.debug(error);
				let message =
					"There has been an error while saving the changes. Please check the validation errors and try again.";
				if (
					error.response &&
					error.response.data &&
					error.response.data.errors
				) {
					message += `\n`;
					for (let key of Object.keys(error.response.data.errors)) {
						let errorMessage = error.response.data.errors[key];

						message += `\n${key}: ${errorMessage}`;
					}
				}
				alert(message);
			},
			saveFinally: function() {
				this.spinner = false;
			},
			markAsDeleted: function() {
				this.selectedPos.forEach((pos) => {
					const item = this.bid.items.find((i) => i.id === pos);

					if (this.isDeleted(item)) {
						item.manualOverride = null;
						item.isDiscontinued = false;
					} else {
						item.manualOverride = this.manualOverrideEnum.deleted;
						item.isDiscontinued = true;
					}
				});

				this.isDirty = true;
				this.runValidation();
			},
			submit: function() {
				let formData = new FormData();

				formData.append("generalComment", this.generalComment);

				if (this.bid.emailCc === null) {
					this.bid.emailCc = [];
				}

				this.bid.emailCc.forEach((emailCc, index) => {
					formData.append("emailCc[" + index + "]", emailCc);
				});

				this.attachments.forEach((file, index) => {
					formData.append("attachments[" + index + "]", file);
				});

				this.isSubmitInProgress = true;
				this.spinner = true;
				axios
					.post("/bid/" + this.bid.id + "/submit", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then(this.submitSuccess)
					.catch(this.submitError)
					.finally(this.submitFinally);
			},
			submitSuccess: function(response) {
				//console.debug(response);
				window.location.reload(true);
			},
			submitError: function(error) {
				console.debug(error);
				let message =
					"There has been an error while submitting the bid. Please check the validation errors and try again.";
				// for (let error of error.)
				if (
					error.response &&
					error.response.data &&
					error.response.data.errors
				) {
					message += `\n`;
					for (let key of Object.keys(error.response.data.errors)) {
						let errorMessage = error.response.data.errors[key];

						message += `\n${key}: ${errorMessage}`;
					}
				}
				alert(message);
				this.isSubmitInProgress = false;
			},
			submitFinally: function() {
				this.spinner = false;
			},
			assign: function() {
				this.spinner = true;
				let formData = new FormData();
				formData.append("assignedToEmail", this.bid.assignedToEmail);
				axios
					.post("/bid/" + this.bid.id + "/assign", formData)
					.then(this.assignSuccess)
					.catch(this.assignError)
					.finally(this.assignFinally);
			},
			assignSuccess: function(response) {
				//console.debug(response);
			},
			assignError: function(error) {
				console.debug(error);
				alert(
					"There has been an error while assigning the bid. Please check the email and try again."
				);
			},
			assignFinally: function() {
				this.spinner = false;
			},
			confirm: function() {
				this.isConfirmInProgress = true;
				window.location.href = "/bid/" + this.bid.id + "/confirm";
			},
			startXLSXDownload: function() {
				this.spinner = true;
				this.setCookie("downloadStarted", 0, 100); //Expiration could be anything... As long as we reset the value
				setTimeout(this.checkDownloadCookie, 1000); //Initiate the loop to check the cookie.
			},
			setCookie: function(name, value, expiracy) {
				var exdate = new Date();
				exdate.setTime(exdate.getTime() + expiracy * 1000);
				var c_value =
					escape(value) +
					(expiracy == null ? "" : "; expires=" + exdate.toUTCString());
				document.cookie = name + "=" + c_value + "; path=/";
			},
			getCookie: function(name) {
				var i,
					x,
					y,
					ARRcookies = document.cookie.split(";");
				for (i = 0; i < ARRcookies.length; i++) {
					x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
					y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
					x = x.replace(/^\s+|\s+$/g, "");
					if (x == name) {
						return y ? decodeURI(unescape(y.replace(/\+/g, " "))) : y; //;//unescape(decodeURI(y));
					}
				}
			},
			checkDownloadCookie: function() {
				if (this.getCookie("downloadStarted") == 1) {
					this.setCookie("downloadStarted", "false", 100); //Expiration could be anything... As long as we reset the value
					this.spinner = false;
				} else {
					this.downloadTimeout = setTimeout(this.checkDownloadCookie, 1000); //Re-run this function in 1 second.
				}
			},
			updateViaFilledBS: function() {
				if (this.$refs.updateViaFilledBs.files.length === 0) {
					return;
				}
				this.spinner = true;
				let formData = new FormData();
				formData.append("file", this.$refs.updateViaFilledBs.files[0]);

				axios
					.post("/bid/" + this.bid.id + "/upload-xlsx", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then(this.updateViaFilledBSSuccess)
					.catch(this.updateViaFilledBSError)
					.finally(this.updateViaFilledBSFinally);
			},
			updateViaFilledBSSuccess: function(response) {
				window.location.reload(true);
			},
			updateViaFilledBSError: function(error) {
				console.debug(error);
				let message =
					"There has been an error while uploading the sheet. Please check the validation errors and try again.";
				if (
					error.response &&
					error.response.data &&
					error.response.data.errors
				) {
					message += `\n`;
					for (let key of Object.keys(error.response.data.errors)) {
						let errorMessage = error.response.data.errors[key];

						message += `\n${key}: ${errorMessage}`;
					}
				}
				alert(message);
			},
			updateViaFilledBSFinally: function() {
				this.$refs.updateViaFilledBs.value = "";
				this.spinner = false;
			},
			changeConversion: function(item, newValue) {
				item.conversion = newValue
					? parseFloat(newValue.toString().replace(",", "."))
					: null;
			},
			changeBidPrice: function(item, newValue) {
				item.bidPrice = newValue
					? parseFloat(newValue.toString().replace(",", "."))
					: null;
			},
			changeReferenceLine: function(item, newValue) {
				if (newValue.trim().length == 0) {
					item.referenceLine = null;
				} else {
					item.referenceLine = newValue.trim();
				}
			},
			isDeleted: function(item) {
				return item.manualOverride === this.manualOverrideEnum.deleted;
			},
			isAdded: function(item) {
				return item.manualOverride === this.manualOverrideEnum.added;
			},
			isItemFreshAdded: function(item) {
				return this.isAdded(item) && !item.id;
			},
			addLineItem: function() {
				if (this.errorsForNewItem()) {
					return;
				}

				this.bid.items.push(this.newItem);
				this.generateNewItem();
				this.isDirty = true;
			},
			sortByFilter: function(sorter, manualOverrideType = null) {
				this.currentSorter = sorter;
				this.currentSorterManualOverrideType = manualOverrideType;
			},
			onFilterInput: function() {
				this.page = 1;
				this.selectedPos = [];
			},
			onFilterSelect: function(event) {
				this.selectedPos = [];

				const select = event.target;
				const keys = {
					added: "added",
					deleted: "deleted",
				};
				let key = null;
				let keyOtherwise = null;

				switch (parseInt(select.value)) {
					case this.manualOverrideEnum.added:
						key = keys.added;
						keyOtherwise = keys.deleted;
						break;
					case this.manualOverrideEnum.deleted:
						key = keys.deleted;
						keyOtherwise = keys.added;
						break;
				}

				if (!key) {
					Object.keys(keys).forEach((key) => (this.filters[key] = ""));
					return;
				}

				this.filters[key] = select.value;
				this.filters[keyOtherwise] = "";
			},
			mapManualOverrideForItem: function(item) {
				if (!this.currentSorterManualOverrideType) {
					return item;
				}

				const sortByAdded =
					this.currentSorterManualOverrideType ===
					this.manualOverrideEnum.added;

				const addValue = sortByAdded ? 1 : -1;
				const deleteValue = sortByAdded ? -1 : 1;
				const changedItem = JSON.parse(JSON.stringify(item));

				switch (changedItem.manualOverride) {
					case this.manualOverrideEnum.added:
						changedItem.manualOverride = sortByAdded ? addValue : 0;
						break;
					case this.manualOverrideEnum.deleted:
						changedItem.manualOverride = sortByAdded ? 0 : deleteValue;
						break;
					default:
						changedItem.manualOverride = 0;
						break;
				}

				return changedItem;
			},
			mapFilterNameOnPropertyName: function(filterName) {
				if (Object.keys(this.manualOverrideEnum).includes(filterName)) {
					return "manualOverride";
				}
				return filterName;
			},
			getFilteredItemsByFilters: function(filtered) {
				const filter = (propertyName) => {
					const itemPropertyName = this.mapFilterNameOnPropertyName(
						propertyName
					);

					return filtered.filter((item) => {
						if (this.filters.added && this.filters.deleted) {
							return item.manualOverride !== null;
						}
						return (
							`${item[itemPropertyName]}`
								.toLowerCase()
								.indexOf(this.filters[propertyName].toLowerCase()) > -1
						);
					});
				};

				for (let propertyName in this.filters) {
					if (this.filters[propertyName].length > 0) {
						filtered = filter(propertyName);
					}
				}

				return filtered;
			},
			changePage: function(page) {
				this.page = page;
			},
			sendBidLink: function() {
				// Zeige Ladezustand an
				this.spinner = true;

				axios
					.post(`/bid/${this.bid.id}/send-link`) 
					.then(response => {
						this.spinner=false;
						setTimeout(() => {
							alert('Bid link successfully sent.');
						}, 10);

					})
					.catch(error => {
						this.spinner=false;
						setTimeout(() => {
							alert('Error sending Bid-Link. Please contact support.');
						}, 10);
					});
			},
		},
		computed: {
			getBidError() {
				if (
					this.bidErrors &&
					this.bidErrors["bid"] &&
					this.bidErrors["bid"]["items"]
				) {
					return this.bidErrors["bid"]["items"];
				}
				return undefined;
			},
			selectAllPos: {
				get: function() {
					return this.bid.items && Object.keys(this.bid.items).length > 0
						? Object.keys(this.bid.items).length === this.selectedPos.length
						: false;
				},
				set: function(value) {
					let selected = [];

					if (value) {
						for (let key of Object.keys(this.bid.items)) {
							selected.push(this.bid.items[key].id);
						}
					}

					this.selectedPos = selected;
				},
			},
			filteredItems: function() {
				let filtered = [];
				if (!this.isShowOnlyErrors) {
					filtered = this.bid.items;
				} else {
					for (let item of this.bid.items) {
						if (
							this.bidErrors &&
							this.bidErrors[item.id] &&
							Object.keys(this.bidErrors[item.id]).length > 0
						) {
							filtered.push(item);
						}
					}
				}

				if (!filtered || filtered.length < 0) {
					return filtered;
				}

				return this.getFilteredItemsByFilters(filtered);
			},
			itemsToShow: function() {
				let self = this;
				let filtered = this.filteredItems;

				if (!filtered || filtered.length < 0) {
					return filtered;
				}

				if (this.sorting) {
					filtered.sort(function(a, b) {
						let field = self.sorting.field;
						let direction = self.sorting.direction;

						const l = self.mapManualOverrideForItem(a);
						const r = self.mapManualOverrideForItem(b);

						if (direction === "asc") {
							if (field === "productNumber") {
								if (parseInt(l[field]) < parseInt(r[field])) {
									return -1;
								} else if (parseInt(l[field]) > parseInt(r[field])) {
									return 1;
								} else {
									return 0;
								}
							} else {
								if (l[field].toLowerCase() < r[field].toLowerCase()) {
									return -1;
								} else if (l[field].toLowerCase() > r[field].toLowerCase()) {
									return 1;
								} else {
									return 0;
								}
							}
						} else {
							if (field === "productNumber") {
								if (parseInt(l[field]) < parseInt(r[field])) {
									return 1;
								} else if (parseInt(l[field]) > parseInt(r[field])) {
									return -1;
								} else {
									return 0;
								}
							} else {
								if (l[field].toLowerCase() < r[field].toLowerCase()) {
									return 1;
								} else if (l[field].toLowerCase() > r[field].toLowerCase()) {
									return -1;
								} else {
									return 0;
								}
							}
						}
					});
				} else {
					filtered.sort(function(a, b) {
						if (a.productName.toLowerCase() < b.productName.toLowerCase()) {
							return -1;
						} else if (
							a.productName.toLowerCase() > b.productName.toLowerCase()
						) {
							return 1;
						} else {
							return 0;
						}
					});
				}

				filtered = filtered.slice(
					(this.page - 1) * this.limit,
					this.page * this.limit
				);

				return filtered;
			},
			canConfirm: function() {
				if (this.isConfirmInProgress) {
					return false;
				}
				if (this.isDirty) {
					return false;
				}
				if (!this.bidErrors) {
					return false;
				}
				if (Object.keys(this.bidErrors).length > 0) {
					return false;
				}
				return true;
			},
			canUploadToCt: function() {
				if (this.isDirty) {
					return false;
				}
				if (!this.bidErrors) {
					return false;
				}
				if (Object.keys(this.bidErrors).length > 0) {
					return false;
				}
				return true;
			},
			sorting: function() {
				if (!this.currentSorter) {
					return null;
				}

				let sortedField = this.sorters[this.currentSorter];
				if (!sortedField) {
					return null;
				}

				return {
					field: this.currentSorter,
					direction: sortedField,
				};
			},
			hasToOrCcEmail: function() {
				if (
					this.bid.emailTo &&
					this.bid.emailTo.length > 0 &&
					this.bid.emailTo[0].length > 0
				) {
					return true;
				}
				if (
					this.bid.emailCc &&
					this.bid.emailCc.length > 0 &&
					this.bid.emailCc[0].length > 0
				) {
					return true;
				}
				return false;
			},
			canEditEmails: function() {
				if (
					this.bid.status === BID_STATUS_NEW &&
					!this.bid.submittedAtTimestamp
				) {
					return true;
				} else {
					return false;
				}
			},
			canShowCtStatuses: function() {
				return this.bid.status === BID_STATUS_CLOSED;
			},
			isFiltered: function() {
				for (let key of Object.keys(this.filters)) {
					if (this.filters[key]) {
						return true;
					}
				}

				return false;
			},
		},
		watch: {
			isShowOnlyErrors: function(newValue, oldValue) {
				if (newValue !== oldValue) {
					this.page = 1;
				}
			},
		},
		delimiters: ["<%", "%>"],
	});
}
